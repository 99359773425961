import Mustache from 'mustache';
import filter from 'lodash/filter.js';
import mapValues from 'lodash/mapValues.js';
import map from 'lodash/map.js';
import forEach from 'lodash/forEach.js';
import keys from 'lodash/keys.js';
import includes from 'lodash/includes.js';
import keyBy from 'lodash/keyBy.js';
import find from 'lodash/find.js';


export const parseField = (field) => {
    if (field.type && field.type.startsWith('Json')) {
        return parseJson(field.value);
    } else {
        return field.value;
    }
};

const parseJson = (value) => {
    try {
        return JSON.parse(value);
    } catch {
        return value;
    }
};

export const parseFields = (fields) => {
    const parsed = map(fields, (field) => ({
        ...field,
        value: parseField(field)
    }));
    return parsed;
};

export const getFieldsForContent = (block, culture) => {
    const filtered = filter(block.content, (content) => content.culture === culture);
    if (filtered.length >= 1) {
        return parseFields(filtered[0].fields);
    }
    return null;
};

export const getKeyValueContentFieldsForBlock = (block, culture) => {
    const content = getFieldsForContent(block, culture);
    return mapValues(keyBy(content, 'name'), 'value');
};

export const isBrowserSupported = () => {
    const {detect} = require('detect-browser');
    const browser = detect();
    const name = browser.name;

    if (name === 'ie') {
        return false;
    } else {
        return true;
    }

    let supported;
    switch (name) {
        case 'chrome':
        case 'firefox':
        case 'edge':
        case 'safari':
        case 'ios':
        case 'crios':
            supported = true;
            break;
        default:
            supported = false;
    }
    return supported;
};


export const hasWindow = () => {
    let hasWindow = false;
    try {
        if (window) {
            hasWindow = true;
        }
    } catch (ex) {
        hasWindow = false;
    }

    return hasWindow;
};

export const parseText = (text, data) => {
    if (data) {
        return Mustache.render(text, data);
    }
    return text;
};

// export const defaultPageNames = {
//     home: '/',
//     vehicles: 'stock',
//     vehicleDetail: 'vehicle',
//     vehicleTakeOver: 'takeover',
//     vehicleCompare: 'vehicle-compare',
//     vehicleSearchAgent: 'search-agent',
//     vehicleReservation: 'reservation',
//     search: 'search',
//     contact: 'contact',
//     contactDetail: 'contact',
//     services: 'services',
//     about: 'about',
//     privacy: 'privacy',
//     blog: 'blog',
//     blogPost: 'blog-post',
//     favorites: 'favorites',
//     login: 'login',
//     registration: 'registration',
//     forgotPassword: 'forgot-password',
//     resetPassword: 'reset-password',
//     financingInformation: 'financing-information',
//     account: 'account',
//     accountPurchases: 'account-purchases',
//     accountOverview: 'account-overview',
//     accountProfile: 'account-profile',
//     accountChangePassword: 'account-change-password',
//     accountCarAgent: 'account-car-agent',
//     accountFavorites: 'account-favorites',
//     404: '404',
//     purchase: 'purchase',
//     purchaseLogin: 'purchase/login',
//     purchaseRegistration: 'purchase/registration',
// };
//
// export const PageTypes = keys(defaultPageNames).reduce((a, v) => ({...a, [v]: v}), {});


export const resolveUrlsMultiLanguage = (defaultPagePathsByPageTypeName, pages) => {
    // Used for BW compatible, but depricated
    let result = {};
    forEach(keys(defaultPagePathsByPageTypeName), (pageType) => {
        const pagesInDb = filter(pages, (p) => p.type === pageType || (p.type === 'index' && pageType === 'home'));
        const defaultPageValue = defaultPagePathsByPageTypeName[pageType];

        if (pagesInDb.length > 0) {
            forEach(pagesInDb, (p) => {
                let translations = JSON.parse(p.slug);

                forEach(keys(translations), (lng) => {
                    if (translations[lng] === '' && defaultPageValue !== '/') {
                        translations[lng] = defaultPageValue;
                    }
                });

                result[p.url] = translations;
            });
        } else {
            result[defaultPageValue] = {
                en: defaultPageValue,
                nl: defaultPageValue,
                fr: defaultPageValue,
                de: defaultPageValue
            };
        }
    });

    const customPages = filter(pages, (p) => p.type === 'custom');
    forEach(customPages, (page) => {
        const defaultPageValue = page.url;
        let translations = JSON.parse(page.slug);

        forEach(keys(translations), (lng) => {
            if (translations[lng] === '') {
                translations[lng] = defaultPageValue;
            }
        });
        result[defaultPageValue] = translations;
    });

    return result;
};

export const tryParse = (value) => {
    try {
        return JSON.parse(value);
    } catch (ex) {
        return value;
    }
};

export const resolveTranslationBySlug = (page, lng) => {
    const slug = tryParse(page.slug);
    if (slug && slug[lng] && slug[lng] !== '') {
        return slug[lng];
    } else {
        return page.url;
    }
};

export const parsePage = (page, language, variables, data) => {
    let result = page.url;

    // New return server generated paths!!
    if (page && page.paths) {
        result = JSON.parse(page.paths)[language]
    }

    if (variables) { // variables for seo pages /make/model/ f.ex
        result = `${result}${variables}/`
    }

    if (result && result.indexOf('{{') > -1) {
        result = parseText(result, data);
    }

    return result

    // Old code

    // let result = page.url;
    //
    // if (page.migration) {
    //     // if a page is a migration page, return the exact string from the slug
    //     // F.e blogposts of delorge must be exact the same example /first-blogpost an not /nl/blog/first-blogpost (our structure)
    //
    //     const slug = tryParse(page.slug);
    //     if (slug && slug[lng] && slug[lng] !== '') {
    //         return `/${slug[lng]}`;
    //     } else {
    //         return undefined;
    //     }
    // } else {
    //     // if page has slug --> url is the slug else url is de page-name
    //     const slug = tryParse(page.slug);
    //     if (slug && slug[lng] && slug[lng] !== '') {
    //         if (page.type === "blogPost") {
    //             result = `blog/${slug[lng]}`;
    //         } else if (page.type === "vehicles" && includes(page.url, '{{')) {
    //             // dyanmic vehicle seo page  /aanbod/audi/a4
    //             // fisrt part is the page slug
    //             const pagePart = page.url.substring(0, page.url.indexOf('/'));
    //             result = page.url.replace(pagePart, slug[lng]);
    //         } else {
    //             result = slug[lng];
    //         }
    //     }
    // }

    // result = multiLanguage ? `/${lng}/${result}` : `/${result}`;

    // if (result && result.indexOf('{{') > -1) {
    //     result = parseText(result, data);
    // }
    //
    // if (data && data.dealerGroup && result.indexOf('dealerGroup') > -1) {
    //     result = result.replace('dealerGroup', data.dealerGroup.key);
    // }

    // if (result.indexOf('index') > -1) {
    //     result = result.replace('index', '');
    // }

    // matchpath for pages with variables f.ex /page-x/:make/:model/:reference
    // if (page.matchPath && page.matchPath !== '') {
    //     result = result + page.matchPath;
    // }

    // if (result === '') {
    //     result = '/';
    // } else if (result !== '/' && !result.match(/\/$/)) {
    //     result = `${result}/`; // trailing slash
    // }
    //
    // // force trailing slash
    // if (!result.endsWith('/')) {
    //     result = `${result}/`
    // }

    return result;
};

export const isDynamicPage = (url) => {
    return url.indexOf('{{') > -1 || url.indexOf(':') > -1 || includes(url, 'dealerGroup');
};

export const isCreationOfPageAllowed = (page, pageTypes, pageCategoriesToIgnore) => {
    // pageCategories to ignore
    if (includes(pageCategoriesToIgnore, page.category)) {
        return false
    }

    // resolve page_type of page
    const pageType = find(pageTypes, (pt) => pt.name === page.type)
    if (pageType && pageTypes.isTemplate) {
        return false
    }

    return true
}

export const b64DecodeUnicode = (str) => {
    try {
        return decodeURIComponent(
            atob(str)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join('')
        ).replace(/&nbsp;/g, ' ');
    } catch (e) {
        return undefined;
    }
};

export const mapArrayToString = (arr, separator = ',') => {
    let result = '';
    forEach(arr, (item) => {
        if (result === '') {
            result = item;
        } else {
            result = `${result}${separator}${item}`;
        }
    });
    return result;
};

