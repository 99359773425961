import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { DataContextProvider } from 'dealer-website-components';
import { resolveUrlsMultiLanguage } from '../utils';
import { settings } from '../../config/index.mjs';

export default function RootWrapper(props) {
  const query = useStaticQuery(graphql`
    query MyQuery {
      cms {
        pagesData {
          id
          typeName
          defaultPath
          matchPath
          category
          paths
        }

        pages {
          url
          type
          slug
        }
        pageTypes {
          name
          path
        }
      }
    }
  `);

  const pages = query.cms.pages;
  const defaultPagePathsByPageTypeName =
    query && query.cms.pageTypes
      ? query.cms.pageTypes.reduce((acc, item) => {
          acc[item.name] = item.path;
          return acc;
        }, {})
      : {};

  const resolveGraphQlEndpoint = () => {
    if (settings.PATH_PREFIX && settings.PATH_PREFIX !== '') {
      return settings.PATH_PREFIX + '/graphql';
    } else {
      return '/graphql';
    }
  };

  const dataContextConfiguration = {
    graphqlEndpoint: resolveGraphQlEndpoint(),
    pagesData: query.cms.pagesData,
    urls: defaultPagePathsByPageTypeName, // TODO: delete when every client is updated to core 2.7.x
    urlsMultiLanguage: resolveUrlsMultiLanguage(defaultPagePathsByPageTypeName, pages), // TODO: delete when every client is updated to core 2.7.x
    features: {
      subscriptions: settings.SUBSCRIPTIONS,
      account: settings.ACCOUNT
    }
  };

  return <DataContextProvider configuration={dataContextConfiguration}>{props.children}</DataContextProvider>;
}
