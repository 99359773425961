export const customerSettings = {
COMPANY_CODE: "declerc-selection",
MODE: "production",
VERSION: 1.1,
DOMAIN: 'declerc-selection.qa.digex.be',
HEAD_JAVASCRIPT_URLS: [],
BODY_JAVASCRIPT_URLS: [],
LANGUAGES: ['fr'],
FORCE_SINGLE_LANGUAGE_IN_URL: false,
PATH_PREFIX: "",

GOOGLE_ANALYTICS_IDENTIFICATION: '',
GOOGLE_ANALYTICS_ID: '',
GOOGLE_TAG_MANAGER_ID: '',
HOTJAR_ID: '',
MAPBOX_TOKEN: 'pk.eyJ1IjoiYXV0cmFsaXMiLCJhIjoiY2toeXR2emhwMDk0OTJ6cWh0Y3JjeG5kaSJ9.YCvAWa5Wyqof0wPdueve5w',
RECAPTCHA_KEY: '6LcZyaUZAAAAAOrMBxDUfdVDq2xKcHORceibmov3',
INTERCOM_ID: '',
FINANCING: true,
FINANCING_SETTINGA: 'Example',
FINANCING_TEXT: true,
RESERVATION: false,
FAVORITES: true,
VEHICLE_COMPARE: true,
VEHICLE_SEGMENTATION: false,
DEALER_GROUPS: false,
SEO: true
};