/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import 'dealer-website-components/dist/css/index.css';
import React from 'react';
import RootWrapper from './src/components/RootWrapper';


const wrapRootElement = ({ element }) => <RootWrapper>{element}</RootWrapper>;

const scrollTo = (id) => () => {
  let offset = 0;
  const el = document.querySelector(id);

  try {
    const stickies = document.getElementsByClassName('sticky');
    for (let key in stickies) {
      const element = stickies[key];
      if (element && element.offsetHeight && element.offsetHeight > 0) {
        offset += element.offsetHeight;
      }
    }
  } catch (ex) {
    console.log(ex)
  }

  if (el) return window.scrollTo(0, el.offsetTop - offset);
  return false;
};

const onRouteUpdate = ({ location: { hash } }) => {
  if (hash) {
    window.setTimeout(scrollTo(hash), 200);
  }
};

const shouldUpdateScroll = () => {
  return false;
};

export { wrapRootElement, onRouteUpdate, shouldUpdateScroll };
